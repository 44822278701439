/**
 * @Description Constant for the module
 * @FileName moduleConstants.js
 * @Author PUT YOUR NAME
 * @CreatedOn 08 February, 2021 16:08:16
 * @IssueID Issue Detail
 */
//  const NS = 'static:';
// const NS = 'static:';
const ROUTE_HOME = '/activity';

export const routeConstant = {
  HOME: `${ROUTE_HOME}/home`,
  ACTIVITY_PROCESS_ROUTE: `${ROUTE_HOME}/activityProcess`,
  ACTIVITY_PROCESS_EXT_ROUTE: `${ROUTE_HOME}/activityProcessExt`,
  ACTIVITY_UNPROCESS_ROUTE: `${ROUTE_HOME}/activityUnProcess`,
  FPA_MONITORING_ROUTE: `${ROUTE_HOME}/fpaMonitoring`,
  BATCH_NUMBER_ROUTE: `${ROUTE_HOME}/batchNumber`,
  ADJUSTMENT_XERROR_ROUTE: `${ROUTE_HOME}/adjXError`,
};

/**
  * Constants for ACL Keys
  */
export const aclKeysConstant = {
  ACTIVITY_MENU: 'RBAC_ACTIVITY',
  ACTIVITY_PROCESSED: 'RBAC_DP_MENU',
  ACTIVITY_UNPROCESSED: 'RBAC_DP_MENU',
  RBAC_DP_MENU: 'RBAC_DP_MENU',
  RBAC_EAP_EXPORT: 'RBAC_EAP_EXPORT',

  RBAC_ACTVTP_MENU: 'RBAC_ACTVTP_MENU',
  RBAC_AP_REVERSE: 'RBAC_ACTVTP_REVERSE',
  RBAC_AP_REV_REPROC: 'RBAC_ACTVTP_REV_REPROC',
  RBAC_AP_EXPORT: 'RBAC_ACTVTP_EXPORT',
  RBAC_EAP_MENU: 'RBAC_EAP_MENU',
  RBAC_EXT_ACTIVITY_PROCESS_MENU: 'RBAC_EXT_ACTIVITY_PROCESS_MENU',

  RBAC_AUP_MENU: 'RBAC_AUP_MENU',
  RBAC_AUP_PROCESS_REALTIME: 'RBAC_AUP_PROCESS_REALTIME',
  RBAC_AUP_PROCESS_PENDING: 'RBAC_AUP_PROCESS_PENDING',
  RBAC_AUP_PROCESS_NONPENDING: 'RBAC_AUP_PROCESS_NONPENDING',
  RBAC_AUP_DELETE: 'RBAC_AUP_DELETE',
  RBAC_AUP_EXPORT: 'RBAC_AUP_EXPORT',

  RBAC_NOTIFICATION_SUPPORT_ACCESS: 'RBAC_NOTIFICATION_SUPPORT_ACCESS',
  RBAC_NOTIFICATION_MENU: 'RBAC_NOTIFICATION_MENU',
};

/**
  * Constants for static content
  */
export const staticLabelKeys = {
  ACTIVITY_MENU: 'RBAC_ACTIVITY',
  AP_LABEL_EXPORT_MOD_TITLE: 'AP_LABEL_EXPORT_MOD_TITLE',
  RBAC_ACTVTP_MENU: 'RBAC_ACTVTP_MENU',
  RBAC_AUP_MENU: 'RBAC_AUP_MENU',
  RBAC_EXT_ACTIVITY_PROCESS_MENU: 'RBAC_EXT_ACTIVITY_PROCESS_MENU',
  RBAC_NOTIFICATION_MENU: 'RBAC_NOTIFICATION_MENU',
  JS_PAGE_TITLE: 'JS_PAGE_TITLE',
  // static label keys for Daily Process
  AP_PAGE_TITLE: 'AP_PAGE_TITLE',
  AUP_PAGE_TITLE: 'AUP_PAGE_TITLE',
  AP_LABEL_ASSIGMENT_DONE: 'AP_LABEL_ASSIGMENT_DONE',
  AP_LABEL_ASSIGNMENT_INDICATOR: 'AP_LABEL_ASSIGNMENT_INDICATOR',
  AP_LABEL_CHEQUE_REFERENCE_NUMBER: 'AP_LABEL_CHEQUE_REFERENCE_NUMBER',
  AP_LABEL_COMMISSION_RATE: 'AP_LABEL_COMMISSION_RATE',
  AP_LABEL_DURATION_PAID: 'AP_LABEL_DURATION_PAID',
  AUP_LABEL_ERROR_FLAG: 'AUP_LABEL_ERROR_FLAG',
  AP_LABEL_OVERRIDE_ADVISOR: 'AP_LABEL_OVERRIDE_ADVISOR',
  AUP_LABEL_PENDING_ACCOUNT: 'AUP_LABEL_PENDING_ACCOUNT',
  AP_LABEL_PENDING_ACCOUNT_FLAG: 'AP_LABEL_PENDING_ACCOUNT_FLAG',
  AP_LABEL_RECORD_STATUS: 'AP_LABEL_RECORD_STATUS',
  AP_LABEL_SWEEP_BONUS_EARNED: 'AP_LABEL_SWEEP_BONUS_EARNED',
  AP_LABEL_SWEEP_BONUS_PAID: 'AP_LABEL_SWEEP_BONUS_PAID',
  AP_LABEL_SWEEP_CALCULATOR_SALE_CREDITS: 'AP_LABEL_SWEEP_CALCULATOR_SALE_CREDITS',
  AP_LABEL_SWEEP_RATE: 'AP_LABEL_SWEEP_RATE',
  AP_LABEL_TRANSFERRED: 'AP_LABEL_TRANSFERRED',
  AP_LABEL_USER_DEPT_OR_BRANCH_ID: 'AP_LABEL_USER_DEPT_OR_BRANCH_ID',
  AUP_LABEL_RATE: 'AUP_LABEL_RATE',
  AUP_LABEL_RECORD_STATUS: 'AUP_LABEL_RECORD_STATUS',
  AUP_LABEL_DURATION_PAID: 'AUP_LABEL_DURATION_PAID',
  AUP_LABEL_PENDING_ACCOUNTING_FLAG: 'AUP_LABEL_PENDING_ACCOUNTING_FLAG',
  AUP_LABEL_GAIN_OR_LOSS_ACCT: 'AUP_LABEL_GAIN_OR_LOSS_ACCT',
  ARIA_RM_BUTTON_ADD: 'ARIA_RM_BUTTON_ADD',
  REVERSE_CONFIRM_MESSAGE: 'ACTVTP_REVERSE_CONFIRM',
  REPROCESS_CONFIRM_MESSAGE: 'ACTVTP_REV_REPRO_CONFIRM',
  AUP_PROCESS_NOW_CONFIRMATION: 'AUP_PROCESS_NOW_CONFIRMATION',
  AUP_PROCESS_NOW_SUCCESS: 'AUP_PROCESS_NOW_SUCCESS',
  AUP_DELETE_CONFIRMATION: 'AUP_DELETE_CONFIRMATION',
  AUP_DELETE_SUCCESS: 'AUP_DELETE_SUCCESS',
  AUP_DELETE_SELECTED_RECORD_COUNT: 'AUP_DELETE_SELECTED_RECORD_COUNT',
  AUP_DELETE_RECORD_NOT_ELIGIBLE_COUNT: 'AUP_DELETE_RECORD_NOT_ELIGIBLE_COUNT',
  AP_AUP_REC_LOCKED: 'AP_AUP_REC_LOCKED',
  AP_AUP_PROCESS_SUCCESS: 'AP_AUP_PROCESS_SUCCESS',
  AP_AUP_LABEL_JOB_ID: 'AP_AUP_LABEL_JOB_ID',
  AUP_REC_UNAVAILABLE_LOCKED: 'AUP_REC_UNAVAILABLE_LOCKED',
  AP_AUP_ACTIVITIES_NOT_SUBMITTED: 'AP_AUP_ACTIVITIES_NOT_SUBMITTED',
  AP_AUP_ACTIVITIES_SUBMITTED: 'AP_AUP_ACTIVITIES_SUBMITTED',
  AP_AUP_PROCEED_CONFIRM: 'AP_AUP_PROCEED_CONFIRM',
  AP_AUP_REMAINING_PROCEED_CONFIRMATION: 'AP_AUP_REMAINING_PROCEED_CONFIRMATION',
  BULK_REPROCESS_CONFIRM_MESSAGE: 'BULK_REPROCESS_CONFIRM_MESSAGE',
  BULK_REPROCESS_CONFIRM_MESSAGE_RECORDS_COUNT: 'BULK_REPROCESS_CONFIRM_MESSAGE_RECORDS_COUNT',
  BULK_REPROCESS_CONFIRM_MESSAGE_RECORDS_VALID: 'BULK_REPROCESS_CONFIRM_MESSAGE_RECORDS_VALID',
  BULK_PROCESS_CONCURRENCY_MESSAGE: 'BULK_PROCESS_CONCURRENCY_MESSAGE',

  // Activiy Processed EXT
  EAP_LABEL_LAST_NAME: 'EAP_LABEL_LAST_NAME',
  EAP_LABEL_COMP_AMT: 'EAP_LABEL_COMP_AMT',
  EAP_LABEL_POLICY_NUM: 'EAP_LABEL_POLICY_NUM',
  EAP_LABEL_PLAN_NAME: 'EAP_LABEL_PLAN_NAME',
  EAP_LABEL_FROM_ADV_NAME: 'EAP_LABEL_FROM_ADV_NAME',
  EAP_LABEL_ORIGINAL_ADVISOR_NAME: 'EAP_LABEL_ORIGINAL_ADVISOR_NAME',
  EAP_LABEL_ORIGINAL_ADVISOR_CODE: 'EAP_LABEL_ORIGINAL_ADVISOR_CODE',

  // FPA Inquiry Screen
  JS_LABEL_JOBID: 'JS_LABEL_JOBID',
  JS_LABEL_SUBMITTEDCOUNT: 'JS_LABEL_SUBMITTEDCOUNT',
  JS_LABEL_JOBSTATUS: 'JS_LABEL_JOBSTATUS',
  JS_LABEL_ACTIVITYNAME: 'JS_LABEL_ACTIVITYNAME',
  JS_LABEL_SUBMITTEDBY: 'JS_LABEL_SUBMITTEDBY',
  JS_LABEL_JOBSUMITTIME: 'JS_LABEL_JOBSUMITTIME',
  JS_LABEL_JOBENDTIME: 'JS_LABEL_JOBENDTIME',
  JS_DATE_FORMAT: 'DP_DATEFORMAT',
  JS_LABEL_BATCHCOPYMSG: 'JS_LABEL_BATCHCOPYMSG',
  JS_LABEL_BATCHNUM: 'JS_LABEL_BATCHNUM',
  JS_LABEL_BATCHCOPY: 'JS_LABEL_BATCHCOPY',
  JS_LABEL_BATCHLIST: 'JS_LABEL_BATCHLIST',
  JS_LABEL_SUBMITTEDTRANS: 'JS_LABEL_SUBMITTEDTRANS',
  JS_LABEL_ELIGIBLE: 'JS_LABEL_ELIGIBLE',
  JS_LABEL_NOTELIGIBLE: 'JS_LABEL_NOTELIGIBLE',
  JS_LABEL_TOTRECELG: 'JS_LABEL_TOTRECELG',
  JS_LABEL_TOTRECNOTELG: 'JS_LABEL_TOTRECNOTELG',
  JS_LABEL_TOTRECSUB: 'JS_LABEL_TOTRECSUB',
  JS_LABEL_REASON: 'JS_LABEL_REASON',
  JS_LABEL_AGENTCODE: 'JS_LABEL_AGENTCODE',

  MAJ_MSG_DEL_NOTIFICATION: 'MAJ_MSG_DEL_NOTIFICATION',
  MAJ_LABEL_ERR_REPORT: 'MAJ_LABEL_ERR_REPORT',
  MCR_TITLE_LIST: 'MCR_TITLE_LIST',
  MAJ_TITLE_MANUAL_ADJUSTMENTS: 'MAJ_TITLE_MANUAL_ADJUSTMENTS',

};
